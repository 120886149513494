import * as React from "react";
import { Link } from "gatsby";
import DefautLayout from "../layouts/default";

const IndexPage = () => {
  return (
    <DefautLayout title="Datenschutzerklärung">
      <div className="content p-standard wordBreak">
      <section id="header">
        <h1 className="pb-20">
          Datenschutzerklärung von NextGen4Bavaria
        </h1>
        <p>
          NextGen4Bavaria ist eine Initiative des Bayerischen
          Staatsministeriums für Digitales. Die Verarbeitung personenbezogener
          Daten erfolgt stets im Einklang mit der Datenschutz-Grundverordnung
          und in Übereinstimmung mit den für uns geltenden landesspezifischen
          Datenschutzbestimmungen, insbesondere dem Bayerischen
          Datenschutzgesetz.
          <br />
          <br />
          Mittels dieser Datenschutzerklärung möchten wir die Öffentlichkeit
          über Art, Umfang und Zweck der von uns erhobenen, genutzten und
          verarbeiteten personenbezogenen Daten informieren.
        </p>
        </section>

        <h2 className="pt-50 pb-20">A) Allgemeine Informationen</h2>
        <ol>
          <li>
            <p className="fw-bold pb-10 pt-20">
              Name und Kontaktdaten des Verantwortlichen
            </p>
            <p>
              Bayerisches Staatsministerium für Digitales
              <br />
              Oskar-von-Miller-Ring. 35
              <br />
              80333 München
              <br />
              <br />
              Telefon: +49 (0) 89 453549-0
              <br />
              <br />
              E-Mail: poststelle@stmd.bayern.de <br />
              Website:
              <Link to="https://www.stmd.bayern.de">www.stmd.bayern.de</Link>
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">
              Kontaktdaten des/der Datenschutzbeauftragten
            </p>
            <p>
              Behördlicher Datenschutzbeauftragter des
              <br />
              Bayerischen Staatsministeriums für Digitales
              <br />
              Oskar-von-Miller-Ring 35
              <br />
              80333 München
              <br />
              <br />
              Telefon: 089/453549-557
              <br />
              Telefax: 089/453549-242
              <br />
              E-Mail: datenschutzbeauftragter@stmd.bayern.de
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">
              Zwecke und Rechtsgrundlagen für die Verarbeitung personenbezogener
              Daten
            </p>
            <p>
              a) Zweck der Verarbeitung ist die Erfüllung der uns vom
              Gesetzgeber zugewiesenen öffentlichen Aufgaben, insbesondere der
              Information der Öffentlichkeit.
              <br />
              <br />
              b) Die Rechtsgrundlage für die Verarbeitung Ihrer Daten ergibt
              sich, soweit nichts anderes angegeben ist, aus Art. 4 Abs. 1 des
              Bayerischen Datenschutzgesetzes (BayDSG) in Verbindung mit Art. 6
              Abs. 1 UAbs. 1 Buchst. e der Datenschutzgrundverordnung (DSGVO).
              Demnach ist es uns erlaubt, die zur Erfüllung einer uns
              obliegenden Aufgabe erforderlichen Daten zu verarbeiten.
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">
              Empfänger von personenbezogenen Daten
            </p>
            <p>
              a) Der technische Betrieb dieser Webseite erfolgt durch:
              <br />
              <br />
              UnternehmerTUM GmbH
              <br />
              Lichtenbergstr. 6<br />
              85748 Garching b. München
              <br />
              +49 89-18 94 69-0
              <br />
              +49 89-18 94 69-1199
              <br />
              nextgen@unternehmertum.de <br />
              <br />
              b) Gegebenenfalls werden Ihre Daten an die zuständigen Aufsichts-
              und Rechnungsprüfungsbehörden zur Wahrnehmung der jeweiligen
              Kontrollrechte übermittelt.
              <br />
              <br />
              c) Zur Abwehr von Gefahren für die Sicherheit in der
              Informationstechnik können bei elektronischer Übermittlung Daten
              an das Landesamt für Sicherheit in der Informationstechnik
              weitergeleitet werden und dort auf Grundlage der Art. 12 ff. des
              Bayerischen E-Government-Gesetzes verarbeitet werden.
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">
              Dauer der Speicherung der personenbezogenen Daten
            </p>
            <p>
              a) Ihre Daten werden nur solange gespeichert, wie dies unter
              Beachtung gesetzlicher Aufbewahrungsfristen zur Aufgabenerfüllung
              erforderlich ist:
              <br />
              <br />
              b) Eine längere Speicherung ist mit Ihrer Einwilligung möglich,
              beispielsweise um Sie über zukünftige Angebote zu informieren.
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">Ihre Rechte</p>
            <p>
              a) Soweit wir von Ihnen personenbezogene Daten verarbeiten, stehen
              Ihnen als Betroffener nachfolgende Rechte zu:
              <ul>
                <li>
                  Sie haben das Recht auf Auskunft über die zu Ihrer Person
                  gespeicherten Daten (Art. 15 DSGVO).
                </li>
                <li>
                  Sollten unrichtige personenbezogene Daten verarbeitet werden,
                  steht Ihnen ein Recht auf Berichtigung zu (Art. 16 DSGVO).
                </li>
                <li>
                  Liegen die gesetzlichen Voraussetzungen vor, so können Sie die
                  Löschung oder Einschränkung der Verarbeitung verlangen (Art.
                  17 und 18 DSGVO).
                </li>
                <li>
                  Wenn Sie in die Verarbeitung eingewilligt haben oder ein
                  Vertrag zur Datenverarbeitung besteht und die
                  Datenverarbeitung mithilfe automatisierter Verfahren
                  durchgeführt wird, steht Ihnen gegebenenfalls ein Recht auf
                  Datenübertragbarkeit zu (Art. 20 DSGVO).
                </li>
                <li>
                  Falls Sie in die Verarbeitung eingewilligt haben und die
                  Verarbeitung auf dieser Einwilligung beruht, können Sie die
                  Einwilligung jederzeit für die Zukunft widerrufen. Die
                  Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
                  erfolgten Datenverarbeitung wird durch diesen nicht berührt.
                </li>
              </ul>
              b) Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
              Situation ergeben, jederzeit gegen die Verarbeitung Ihrer Daten
              Widerspruch einzulegen, wenn die Verarbeitung ausschließlich auf
              Grundlage des Art. 6 Abs. 1 Buchst. e oder f DSGVO erfolgt (Art.
              21 Abs. 1 Satz 1 DSGVO).
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">
              Beschwerderecht bei der Aufsichtsbehörde
            </p>
            <p>
              Weiterhin besteht ein Beschwerderecht beim Bayerischen
              Landesbeauftragten für den Datenschutz. <br />
              <br />
              Diesen können Sie unter folgenden Kontaktdaten erreichen: <br />
              <br />
              Postanschrift: Postfach 22 12 19, 80502 München <br />
              Adresse: Wagmüllerstraße 18, 80538 München <br />
              Telefon: 089 212672-0 <br />
              Telefax: 089 212672-50 <br />
              E-Mail: poststelle@datenschutz-bayern.de <br />
              Internet:
              <Link to="https://www.datenschutz-bayern.de/">
                https://www.datenschutz-bayern.de/
              </Link>
              <br />
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">Weitere Informationen</p>
            <p>
              Für nähere Informationen zur Verarbeitung Ihrer Daten und zu Ihren
              Rechten können Sie uns unter den oben (zu Beginn von A) genannten
              Kontaktdaten erreichen.
            </p>
          </li>
        </ol>
        <h2 className="pt-50 pb-20">B) Informationen zum Internetauftritt</h2>
        <ol>
          <li>
            <p className="fw-bold pb-10 pt-20">Technische Umsetzung</p>
            <p>
              Unser Web-Server wird durch die UnternehmerTUM GmbH betrieben. Die
              von Ihnen im Rahmen des Besuchs unseres Webauftritts übermittelten
              personenbezogenen Daten werden daher in unserem Auftrag durch die
              UnternehmerTUM GmbH verarbeitet:
              <br />
              <br />
              UnternehmerTUM GmbH
              <br />
              Lichtenbergstr. 6<br />
              85748 Garching b. München
              <br />
              +49 89-18 94 69-0
              <br />
              +49 89-18 94 69-1199
              <br />
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">Protokollierung</p>
            <p>
              a) Wenn Sie diese oder andere Internetseiten aufrufen, übermitteln
              Sie über Ihren Internetbrowser Daten an unseren Webserver. Die
              folgenden Daten werden während einer laufenden Verbindung zur
              Kommunikation zwischen Ihrem Internetbrowser und unserem Webserver
              aufgezeichnet:
              <ul>
                <li>Datum und Uhrzeit der Anforderung</li>
                <li>Name der angeforderten Datei</li>
                <li>Seite, von der aus die Datei angefordert wurde</li>
                <li>
                  Zugriffsstatus (Datei übertragen, Datei nicht gefunden, etc.)
                </li>
                <li>verwendete Webbrowser und verwendetes Betriebssystem</li>
                <li>vollständige IP-Adresse des anfordernden Rechners</li>
                <li>übertragene Datenmenge.</li>
              </ul>
              b) Aus Gründen der technischen Sicherheit, insbesondere zur Abwehr
              von Angriffsversuchen auf unseren Webserver, werden diese Daten
              von uns gespeichert. Nach spätestens sieben Tagen werden die Daten
              durch Verkürzung der IP-Adresse auf Domain-Ebene anonymisiert, so
              dass es nicht mehr möglich ist, einen Bezug auf einzelne Nutzer
              herzustellen.
              <br />
              <br />
              c) Zur Abwehr von Gefahren für die Sicherheit in der
              Informationstechnik werden die Daten an das Landesamt für
              Sicherheit in der Informationstechnik weitergeleitet und dort auf
              Grundlage der Art. 12 ff. des Bayerischen E-Government-Gesetzes
              verarbeitet.
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">Aktive Komponenten</p>
            <p>
              Wir verwenden aktive Komponenten wie Javascript, Java-Applets oder
              Active-X-Controls. Diese Funktion kann durch die Einstellung Ihres
              Internetbrowsers von Ihnen abgeschaltet werden.
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">
              Datenschutzbestimmung für den Einsatz der Vorlesetechnik
              Readspeaker
            </p>
            <p>
              a) ReadSpeaker, ein Dienst der ReadSpeaker Holding B.V.,
              Dolderseweg 2A, Huis ter Heide 3712 BP, Niederlande, der die
              Inhalte unserer Webseite vorliest. Beim Klick auf den
              „Vorlesen“-Button, wird der markierte Text über die IP des Nutzers
              an den ReadSpeaker-Server übergeben und dort eine Audiodatei
              generiert und als Stream an die IP-Adressen des Nutzers
              zurückgesendet. Sofort nach Auslieferung der Audiodatei werden der
              Vorgang und die IP-Adresse des Nutzers auf dem ReadSpeaker-Server
              gelöscht. ReadSpeaker speichert keine personenbezogenen Daten. Die
              Daten werden ausschließlich in der Europäischen Union realisiert
              (Schweden und Irland).
              <br />
              <br />
              b) Bei Nutzung der ReadSpeaker-Funktion werden technisch
              notwendige Cookies auf Ihrem Endgerät gespeichert, um die vom
              Benutzer gewählten Einstellungen beizubehalten
              (Hervorhebungseinstellung, Textgröße usw.) und nach der Session
              bzw. je nach Funktion einem entsprechenden Zeitraum (maximal 30
              Tage) wieder gelöscht. Wird die ReadSpeaker-Funktion nicht
              aktiviert, werden beim Besuch der Webseite keine Cookies auf dem
              Endgerät gespeichert. Um diesen Dienst zu benutzen, müssen Sie den
              vorzulesenden Text markieren und das erscheinende
              Lautsprecher-Symbol anklicken. Die Datenschutzerklärung von
              ReadSpeaker finden Sie unter
              <Link to="https://www.readspeaker.com/de/erklarung-zum-datenschutz/">
                https://www.readspeaker.com/de/erklarung-zum-datenschutz/
              </Link>
              .
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">Cookies</p>
            <p>
              Beim Zugriff auf dieses Internetangebot werden von uns Cookies
              (kleine Dateien) auf Ihrem Gerät mit einer Gültigkeitsdauer von
              elf Monaten gespeichert. Wir verwenden diese dazu, um Ihre
              Zustimmung zur Speicherung der verschiedenen Arten von Cookies
              (Funktion, Leistung, Analyse) nachzuverfolgen. Die meisten Browser
              sind so eingestellt, dass sie die Verwendung von Cookies
              akzeptieren, diese Funktion kann aber durch die Einstellung des
              Internetbrowsers von Ihnen für die laufende Sitzung oder dauerhaft
              abgeschaltet werden.
            </p>
          </li>
          <li>
            <p className="fw-bold pb-10 pt-20">Einbindung von YouTube-Videos</p>
            <p>
              a) Auf unserer Webseite sind Videos der externen Videoplattform
              YouTube eingebunden. Standardmäßig werden dabei lediglich
              deaktivierte Bilder des YouTube-Kanals eingebettet, die keine
              automatisierte Verbindung mit den Servern von YouTube herstellen.
              Damit erhält der Betreiber beim Aufruf der Webseiten keine Daten
              vom Benutzer.
              <br />
              <br />
              b) Sie können selbst entscheiden, ob die YouTube-Videos aktiviert
              werden sollen. Erst wenn Sie das Abspielen der Videos mit Klick
              auf „Dauerhafte Aktivierung“ freigeben, erteilen Sie die
              Einwilligung, dass die dafür erforderlichen Daten (unter anderem
              die Internetadresse der aktuellen Seite sowie die IP-Adresse des
              Anwenders) an den Betreiber übermittelt werden.
              <br />
              <br />
              c) Um die vom Nutzer gewünschte Einstellung zu speichern, wird von
              uns ein Cookie gesetzt, das die Parameter abspeichert. Beim Setzen
              dieser Cookies werden von uns allerdings keine personenbezogenen
              Daten gespeichert, sie enthalten lediglich anonymisierte Daten zur
              Anpassung des Browsers. Anschließend sind die Videos aktiv und
              können vom Nutzer abgespielt werden. Möchten Sie das automatische
              Laden der YouTube-Videos wieder deaktivieren, können Sie unter dem
              Datenschutz-Symbol das Häkchen für die Zustimmung wieder
              entfernen. Damit werden auch die Einstellungen des Cookies
              aktualisiert.
              <br />
              <br />
              d) YouTube ist ein Angebot von YouTube LLC, 901 Cherry Ave., San
              Bruno, CA 94066, USA, einem Tochterunternehmen von Google Inc.,
              1600 Amphitheater Parkway, Mountainview, California 94043, USA.
              Weitere Informationen zu Zweck und Umfang der Datenverarbeitung
              (auch außerhalb der Europäischen Union und außerhalb der USA)
              sowie Informationen zu Einstellungsmöglichkeiten zum Schutz Ihrer
              Privatsphäre erhalten Sie in der Datenschutzerklärung:
              <Link to="https://policies.google.com/privacy?hl=de&gl=de">
                https://policies.google.com/privacy?hl=de&gl=de
              </Link>
              . Wir haben mit Google eine hierfür erforderliche Vereinbarung
              nach den EU-Standardvertragsklauseln geschlossen.
            </p>
          </li>
        </ol>
        <h2 className="pt-50 pb-20">
          C) Informationen zu einzelnen Verarbeitungen
        </h2>
        <ol>
          <li>
            <p className="fw-bold pb-10 pt-20">Elektronische Post (E-Mail)</p>
            <p>
              a) Informationen, die unverschlüsselt per Elektronische Post
              (E-Mail) an das Bayerische Staatsministerium für Digitales
              gesendet werden, können möglicherweise auf dem Übertragungsweg von
              Dritten gelesen werden. Wir können in der Regel auch die Identität
              nicht überprüfen und wissen nicht, wer sich hinter einer
              E-Mail-Adresse verbirgt. Eine rechtssichere Kommunikation durch
              einfache E-Mail ist daher nicht gewährleistet. Wie setzen – wie
              viele E-Mail-Anbieter – Filter gegen unerwünschte Werbung
              (SPAM-Filter) ein, die in seltenen Fällen auch normale E-Mails
              fälschlicherweise automatisch als unerwünschte Werbung einordnen
              und löschen können. E-Mails, die schädigende Programme (Viren)
              enthalten, werden in jedem Fall automatisch gelöscht.
              <br />
              <br />
              b) Wenn Sie schutzwürdige Nachrichten an das Bayerische
              Staatsministerium für Digitales senden wollen, empfehlen wir
              Ihnen, diese zu verschlüsseln und zu signieren, um eine unbefugte
              Kenntnisnahme und Verfälschung auf dem Übertragungsweg zu
              verhindern oder die Nachricht auf konventionellem Postwege zu
              senden.
              <br />
              <br />
              c) Mit S/MIME verschlüsselte E-Mails können Sie schicken an:
              poststelle@stmd.bayern.de
              <br />
              <br />
              <Link to="https://www.ebca.de/nutzung-der-ebca/zertifikate-finden/">
                Den öffentlichen Schlüssel der Poststelle können Sie bei
                TeleTrusT EBCA herunterladen
              </Link>
              bzw. finden diesen hier:
              <Link to="https://www.stmd.bayern.de/wp-content/uploads/2019/02/Poststelle_StMD.zip">
                Download des Schlüssels (ZIP-Datei, 1,4KB)
              </Link>
              . Bitte teilen Sie auch mit, ob und auf welche Weise wir Ihnen zur
              Beantwortung Ihrer Zusendungen verschlüsselte E-Mails übersenden
              können und ob Sie – falls dies nicht möglich ist – mit einer
              unverschlüsselten Antwort per E-Mail auf Ihr Schreiben
              einverstanden sind. Wenn Sie über keine Möglichkeit zum Empfang
              verschlüsselter E-Mails verfügen, bitten wir zur Beantwortung
              Ihrer schutzwürdigen Nachrichten Ihre Postanschrift zu nennen.
            </p>
          </li>
        </ol>
      </div>
    </DefautLayout>
  );
};

export default IndexPage;
